import { useState } from 'react';
import { Checkbox, Icon, MyCombobox, ParamButton, Tooltip } from '~/components';
import { DatePicker } from '~/components/v3';
import { RowControl } from '~/components/v3/RowControl';
import {
  getEntitiesFromPath,
  getPathFromEntities,
  IBulkNamespace,
  IBulkSchema
} from '../components/BulkNamespaceUtil';
import { cn } from '~/lib/utils';
import { BulkSchemaPickerDrawer } from './BulkSchemaPickerDrawer';
import { useFormContext } from 'react-hook-form';
import { BulkSyncForm } from '~/utils';

export interface CutoffFilter {
  namespace?: IBulkNamespace;
  schema?: IBulkSchema;
  dataCutoffTimestamp?: string;
  disableDataCutoff?: boolean;
}

interface CutoffFiltersProps {
  initialCutoffFilters: CutoffFilter[];
  namespaces: IBulkNamespace[];
  onCutoffFiltersChange: (filters: CutoffFilter[]) => void;
}

export function CutoffFilters({
  initialCutoffFilters,
  namespaces,
  onCutoffFiltersChange
}: CutoffFiltersProps) {
  const [activeIndex, setActiveIndex] = useState<number>(null);
  const [filters, setFilters] = useState<CutoffFilter[]>(initialCutoffFilters);
  const { watch, setValue } = useFormContext<BulkSyncForm>();
  const [showGlobalCutoff, setShowGlobalCutoff] = useState<boolean>(!!watch('dataCutoffTimestamp'));

  const isValid = (filter: CutoffFilter) => {
    return !!filter.schema && (!!filter.dataCutoffTimestamp || filter.disableDataCutoff);
  };

  const handleSchema = (schema?: IBulkSchema) => {
    if (schema) {
      const { namespace } = getEntitiesFromPath(namespaces, schema.path);
      const currentFilter = filters[activeIndex];
      const newFilters = filters.map((filter, index) =>
        index === activeIndex
          ? {
              ...currentFilter,
              schema,
              namespace
            }
          : filter
      );
      setFilters(newFilters);
      onCutoffFiltersChange(newFilters);
    }
    setActiveIndex(null);
  };

  const activeFilter = filters[activeIndex];
  const selectedPath = activeFilter
    ? getPathFromEntities(activeFilter?.namespace, activeFilter?.schema)
    : null;

  return (
    <>
      <div className="flex flex-col gap-4 border-t pt-4">
        <div>
          <label className="block pb-2 text-sm font-semibold">Historical data</label>
          <Checkbox
            onChange={e => {
              const c = e.target.checked;
              if (!c) {
                setValue('dataCutoffTimestamp', null);
              }
              setShowGlobalCutoff(c);
            }}
            checked={showGlobalCutoff}
            label="Ignore records updated before cutoff date"
          ></Checkbox>
          {showGlobalCutoff && (
            <DatePicker
              classNames={{ wrapper: 'max-w-[180px] mt-2' }}
              placeholder="Select cutoff date..."
              variant="flat"
              onSelect={v => {
                setValue('dataCutoffTimestamp', `${v}T00:00:00Z`);
              }}
              date={watch('dataCutoffTimestamp')?.split('T')[0]}
            />
          )}
        </div>
        <div>
          <label className="block pb-2 text-sm font-semibold">Object-specific cutoff dates</label>
          <div className="flex w-full flex-col gap-2">
            {!filters?.length && (
              <ParamButton
                action="add"
                onClick={() => {
                  setFilters([{}]);
                }}
              />
            )}
            {filters?.map((filter, index) => {
              return (
                <div key={filter?.schema?.id ?? 'New field'} className="w-full">
                  <label className="thead-text block text-gray-500">Object</label>
                  <div className="flex w-full gap-2">
                    <Tooltip
                      content={
                        <div className="flex items-center">
                          {filter?.namespace?.id && (
                            <>
                              <span>{filter.namespace.id}</span>
                              <Icon name="Disclosure" />
                            </>
                          )}
                          <span>{filter?.schema?.name}</span>
                        </div>
                      }
                      disabled={!filter?.schema?.name}
                    >
                      <button
                        className={cn(
                          'flex h-8 flex-1 items-center gap-1 truncate rounded bg-gray-50 py-1 px-4 text-left hover:bg-indigo-50',
                          !filter?.schema?.id && 'text-gray-500',
                          activeIndex === index && 'bg-indigo-50'
                        )}
                        onClick={() => setActiveIndex(index)}
                      >
                        {filter?.namespace?.id && (
                          <>
                            <span className="min-w-[50px] max-w-fit shrink-0 grow basis-0 truncate text-gray-500">
                              {filter.namespace.name}
                            </span>
                            <Icon name="Disclosure" className="text-gray-500" />
                          </>
                        )}
                        <span className="max-w-fit grow truncate">
                          {filter?.schema?.name ?? 'Pick object...'}
                        </span>
                      </button>
                    </Tooltip>
                    <div className="w-[200px]">
                      <MyCombobox
                        variant="flat"
                        options={[
                          {
                            label: 'Cutoff before',
                            value: 0
                          },
                          {
                            label: 'No cutoff',
                            value: 1
                          }
                        ]}
                        value={
                          filter.disableDataCutoff
                            ? { label: 'No cutoff', value: 1 }
                            : { label: 'Cutoff before', value: 0 }
                        }
                        onChange={v => {
                          const newFilters = filters.map((filter, i) =>
                            index === i ? { ...filter, disableDataCutoff: !!v.value } : filter
                          );
                          setFilters(newFilters);
                          onCutoffFiltersChange(newFilters);
                        }}
                      />
                    </div>
                    <div className="w-[200px]">
                      {!filter.disableDataCutoff && (
                        <DatePicker
                          variant="flat"
                          onSelect={v => {
                            const newFilters = filters.map((filter, i) =>
                              index === i
                                ? { ...filter, dataCutoffTimestamp: `${v}T00:00:00Z` }
                                : filter
                            );
                            setFilters(newFilters);
                            onCutoffFiltersChange(newFilters);
                          }}
                          date={filter.dataCutoffTimestamp?.split('T')[0]}
                        />
                      )}
                    </div>
                    <div className="pt-1.5">
                      <RowControl
                        index={index}
                        total={filters?.length}
                        canAdd={index === filters.length - 1 && isValid(filter)}
                        onAdd={() => {
                          const newFilters = [
                            ...filters.slice(0, index + 1),
                            {},
                            ...filters.slice(index + 1)
                          ];
                          setFilters(newFilters);
                          //onChange(newFilters, true);
                        }}
                        onDelete={() => {
                          const newFilters = filters.filter((f, i) => i !== index);
                          setFilters(newFilters);
                          onCutoffFiltersChange(newFilters);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <BulkSchemaPickerDrawer
        open={activeIndex !== null}
        namespaces={namespaces
          .map(namespace => ({
            ...namespace,
            schemas: namespace.schemas.filter(
              schema => !filters.find(filter => filter?.schema?.id === schema?.id)
            )
          }))
          .filter(namespace => namespace?.schemas?.length)}
        onDismiss={handleSchema}
        selectedPath={selectedPath}
      />
    </>
  );
}
