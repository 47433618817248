import cx from 'clsx';
import { ReactNode, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button, Checkbox, FormBuilder, Icon, JSONSchemaForm, MyInput } from '~/components';
import { resolveRef } from '~/components/common/SchemaForm';
import Select from '~/components/v2/inputs/Select';
import { BulkSourceProperties, Discover } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { BulkSyncForm } from '~/utils';
import { StageCard } from '../../syncs/sync-config';
import { BulkSyncSummary } from '../components/bulk-sync-summary';
import { pick } from 'lodash';
import { JSONSchema4 } from 'json-schema';

function AdvancedSettingsToggle(props: { children: ReactNode }) {
  const [show, toggle] = useToggle();
  return (
    <div className={cx('rounded-b-lg bg-gray-50 pt-3', show ? 'pb-6' : 'pb-3')}>
      <Button theme="ghost" className="ml-2" onClick={toggle}>
        <Icon
          name="Disclosure"
          className={cx(
            '-ml-1 mr-0.5 h-5 w-5 text-gray-500 group-hover:text-gray-800',
            show && 'rotate-90 transform'
          )}
        />
        Advanced settings
      </Button>
      {show && props.children}
    </div>
  );
}

interface DiscoveryFieldProps {
  name: 'tableDiscovery' | 'fieldDiscovery';
  label: string;
  props: BulkSourceProperties;
}

function DiscoveryField({ name, label, props: sourceProps }: DiscoveryFieldProps) {
  const { control, setValue } = useFormContext<BulkSyncForm>();
  const value = useWatch({ control, name });
  const isChecked = value && !(value === Discover.None);

  const options = [
    {
      label: 'All fields',
      value: Discover.All
    }
  ];

  if (sourceProps.supportsSlowMode) {
    options.push(
      {
        label: 'Only incremental fields',
        value: Discover.OnlyIncremental
      },
      {
        label: 'Only non-incremental fields',
        value: Discover.OnlyNonIncremental
      }
    );
  }

  return (
    <div>
      <Checkbox
        checked={isChecked}
        label={label}
        onChange={e => {
          if (e.target.checked) {
            setValue(name, Discover.All);
          } else {
            setValue(name, Discover.None);
          }
        }}
      />
      {isChecked && options.length > 1 && (
        <div className="mt-1 mb-2 ml-5">
          <Select
            variant="filled"
            options={options}
            onChange={v => setValue(name, v.value as Discover)}
            value={options.find(o => o.value === value) || options[0]}
            inputWidth="w-fit"
            optionsWidth=""
          />
        </div>
      )}
    </div>
  );
}

const getAdvancedConfigurationSchema = (schema: JSONSchema4) => {
  if (!schema) {
    return null;
  }
  const path = [
    '#/definitions/WarehouseAdvancedConfiguration',
    '#/definitions/AdvancedConfiguration',
    '#/definitions/BulkDestinationAdvancedConfiguration'
  ].filter(path => resolveRef(schema, path))?.[0];

  return path
    ? {
        ...schema,
        $ref: path,
        definitions: {
          ...pick(schema.definitions, path.split('/')[2])
        }
      }
    : null;
};

export function AdvancedSettings() {
  const { control, register, setValue, watch } = useFormContext<BulkSyncForm>();
  const destination = useWatch({ control, name: 'destination' });
  const bulkSyncConfigForm = useWatch({ control });
  const schemaLabel = watch('source.schemaLabel') || { singular: 'object', plural: 'objects' };
  const sourceProps = watch('source.properties') as BulkSourceProperties;

  const advancedConfiguration = getAdvancedConfigurationSchema(destination?.configurationSchema);

  return (
    <AdvancedSettingsToggle>
      <div className="flex animate-fadeIn flex-col space-y-4 px-6 pt-6">
        <section className="grid w-full max-w-xl gap-4">
          <DiscoveryField
            name="fieldDiscovery"
            label={`Automatically add new fields on selected ${schemaLabel.plural}`}
            props={sourceProps}
          />
          <DiscoveryField
            name="tableDiscovery"
            label={`Automatically add new ${schemaLabel.plural}`}
            props={sourceProps}
          />
        </section>
        <section className="gap 4 grid w-full max-w-xl gap-4">
          {advancedConfiguration && (
            <FormBuilder<BulkSyncForm>
              schema={advancedConfiguration}
              prefix="destinationConfiguration.advanced"
              styleOverrides={{
                'destinationConfiguration.advanced.tableExpiryDays': {
                  input: 'max-w-[60px]'
                },
                'destinationConfiguration.advanced.logFileRetentionDays': {
                  input: 'max-w-[60px]'
                },
                'destinationConfiguration.advanced.deletedFileRetentionDays': {
                  input: 'max-w-[60px]'
                }
              }}
            />
          )}
        </section>
        <Checkbox
          {...register('disableRecordTimestamps')}
          label="Exclude Polytomic timestamp columns"
        />
        <section className="grid w-full max-w-xl gap-4">
          {bulkSyncConfigForm.source?.configurationForm && (
            <JSONSchemaForm
              schema={bulkSyncConfigForm.source.configurationForm.jsonschema}
              formData={watch('sourceConfiguration')}
              uiSchema={{
                ...bulkSyncConfigForm.source.configurationForm?.uischema
              }}
              formContext={{
                classNames: { input: 'max-w-xs' }
              }}
              onChange={value => {
                setValue('sourceConfiguration', value);
              }}
            />
          )}
        </section>
      </div>
    </AdvancedSettingsToggle>
  );
}

export type StageBulkSummaryProps = {
  step: number;
  totalSchemaCount?: number;
  totalSelectedSchemaCount?: number;
};

export const StageBulkSummary = ({ step, totalSelectedSchemaCount }: StageBulkSummaryProps) => {
  const { register, control, getValues, setValue } = useFormContext<BulkSyncForm>();

  const bulkSync = useWatch({ control }) as BulkSyncForm;

  useEffect(() => {
    if (!getValues('name')) {
      setValue(
        'name',
        cx(
          getValues('source.connection.name'),
          'to',
          getValues('destination.connection.name'),
          'sync'
        )
      );
    }
  }, []);

  return (
    <StageCard
      hasStickyHeader={true}
      step={step}
      header="Sync summary"
      className="px-6 pb-6"
      footer={<AdvancedSettings />}
    >
      <BulkSyncSummary
        bulkSync={bulkSync}
        totalSelectedSchemaCount={totalSelectedSchemaCount}
        nameControls={
          <>
            <p className="self-center text-sm font-semibold text-gray-500">Name</p>
            <MyInput {...register('name')} />
          </>
        }
      />
    </StageCard>
  );
};
