import { Reference, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { flatMap, isEqual } from 'lodash';
import { Button, EditPermission, PromptUnsaved } from '~/components';
import Dots from '~/components/v2/feedback/Dots';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import PageLayout from '~/components/v2/layout/PageLayout';
import { Dialog } from '~/components/v3';
import {
  BulkNamespaceSelection,
  BulkSelectedNamespace,
  BulkSourceDocument,
  BulkSourceQuery,
  BulkSourceQueryVariables,
  BulkSyncDocument,
  BulkSyncFragment,
  BulkSyncUpdate,
  CreateBulkSyncDocument,
  DailySchedule,
  Discover,
  Frequency,
  HourlySchedule,
  Operation,
  UpdateBulkSyncDocument,
  WeeklySchedule
} from '~/generated/graphql';
import { useBannerDispatch, useConfirmation, useLazyContinuationQuery } from '~/hooks';
import { BulkSyncForm, hasItems, routes } from '~/utils';
import { getNamespaceUpdateFromState, isNamespaceRoot } from '../components/BulkNamespaceUtil';
import { StageBulkMode } from './stage-bulk-mode';
import { StageBulkObjects } from './stage-bulk-objects';
import { StageBulkSchedule } from './stage-bulk-schedule';
import { StageBulkSummary } from './stage-bulk-summary';
import { StageBulkTarget } from './stage-bulk-target';

type StageStates = 'init' | 'objects' | 'schedule' | 'summary';

const wrapperStyles = 'px-3 pt-3 max-w-5xl mx-auto';

const BulkSyncConfig = () => {
  /* ReactRouter */
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isCreating = !!useRouteMatch({ path: routes.createBulkSync });

  /* Hooks */
  const dispatchBanner = useBannerDispatch();

  /* STATE */
  const { data, loading } = useQuery(BulkSyncDocument, {
    skip: !id,
    variables: { id },
    onCompleted: ({ bulkSync }) => {
      methods.reset({
        name: bulkSync?.name ?? '',
        source: bulkSync?.source,
        destination: bulkSync?.destination,
        destinationConfiguration: bulkSync?.destinationConfiguration,
        sourceConfiguration: bulkSync?.sourceConfiguration,
        mode: bulkSync?.mode || undefined,
        namespaces: bulkSync?.namespaces ?? [],
        schedule: bulkSync?.schedule,
        tags: bulkSync?.tags ?? [],
        fieldDiscovery: bulkSync?.fieldDiscovery,
        tableDiscovery: bulkSync?.tableDiscovery,
        disableRecordTimestamps: !!bulkSync?.disableRecordTimestamps,
        dataCutoffTimestamp: bulkSync?.dataCutoffTimestamp
      });
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });
  const bulkSync = data?.bulkSync;

  const [stage, setStage] = React.useState<StageStates>(isCreating ? 'init' : 'summary');
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [targetRefetchLoading, setTargetRefetchLoading] = React.useState(false);
  const [refetchLoading, setRefetchLoading] = React.useState(false);
  const { confirmation, confirmationMessage, handleConfirmation, resetConfirmation } =
    useConfirmation();
  /* RHF */
  const methods = useForm<BulkSyncForm>({
    defaultValues: {
      fieldDiscovery: Discover.All
    }
  });
  const namespaces = methods.watch('source.namespaces');
  const selectedNamespaces = methods.watch('namespaces');
  const sourceId = methods.watch('source.id');
  const source = methods.watch('source');
  const destination = methods.watch('destination');
  const mode = methods.watch('mode');
  const schemaLabel = methods.watch('source.schemaLabel');
  const namespaceLabel = methods.watch('source.namespaceLabel');
  const schedule = methods.watch('schedule');

  /* Vars */
  const showModeStage = React.useMemo(() => {
    return destination?.supportedModes?.length > 1 ? 1 : 0;
  }, [destination]);

  /* F(x) */
  const handleCompleteModeStage = () => {
    if (isCreating && stage === 'init') {
      setStage('objects');
    }
  };
  const handleCancel = () => {
    if (isCreating || !bulkSync) {
      history.push(routes.bulkSyncsRoot);
      return;
    }
    history.push(generatePath(routes.bulkSyncStatus, { id: bulkSync.id }));
  };

  const totalSelections = React.useMemo(
    () => flatMap(selectedNamespaces, namespace => namespace.schemas).length,
    [selectedNamespaces]
  );

  const setSelectedNamespaces = (namespaces: BulkSelectedNamespace[]) => {
    if (!isEqual(namespaces, selectedNamespaces)) {
      methods.setValue('namespaces', namespaces);
      setIsDirty(true);
    }
  };

  /* MISC Util */
  const prepareBulkSyncUpdate = (data: BulkSyncForm): BulkSyncUpdate => {
    const schedule = data.schedule;
    delete schedule?.__typename;

    const destinationConfiguration = data.destinationConfiguration;
    delete destinationConfiguration?.__typename;

    const sourceConfiguration = data.sourceConfiguration;
    delete sourceConfiguration?.__typename;
    return {
      name: data.name,
      sourceConnectionId: data.source?.connection.id || '',
      destConnectionId: data.destination?.connection.id || '',
      destinationConfiguration,
      sourceConfiguration,
      mode: data.mode,
      namespaces: getNamespaceUpdateFromState(selectedNamespaces),
      tableDiscovery: data.tableDiscovery,
      fieldDiscovery: data.fieldDiscovery,
      disableRecordTimestamps: data.disableRecordTimestamps,
      dataCutoffTimestamp: data.dataCutoffTimestamp,
      schedule
    };
  };

  /* GraphQL */
  const [createBulkSync, { loading: createBulkSyncLoading }] = useMutation(CreateBulkSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    update: (cache, { data }) => {
      if (!data.createBulkSync || handleConfirmation(data.createBulkSync)) {
        return;
      }
      const bulkSync = data.createBulkSync as BulkSyncFragment;
      // make sure get bulkSync doesn't make network call
      cache.writeQuery({
        query: BulkSyncDocument,
        variables: { id: bulkSync.id },
        data: {
          bulkSync
        }
      });
      // update the bulkSyncs list in the cache with
      // new/updated bulkSync to avoid network call
      cache.modify({
        fields: {
          bulkSyncs: (existingRefs: Reference[], { toReference, readField }) => {
            const bulkSyncRef = toReference({
              __typename: 'BulkSync',
              id: bulkSync.id
            });
            if (!existingRefs) {
              return [bulkSyncRef];
            }
            const found = existingRefs.some(ref => readField('id', ref) === bulkSync.id);
            if (found) {
              return existingRefs;
            }
            return [...existingRefs, bulkSyncRef];
          }
        }
      });
      cache.gc();
      setIsDirty(false);
      history.push(generatePath(routes.bulkSyncStatus, { id: bulkSync.id }));
    }
  });
  const [updateBulkSync, { loading: updateBulkSyncLoading }] = useMutation(UpdateBulkSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    update: (cache, { data }) => {
      if (!data.updateBulkSync || handleConfirmation(data.updateBulkSync)) {
        return;
      }
      const bulkSync = data.updateBulkSync as BulkSyncFragment;
      // make sure get bulkSync doesn't make network call
      cache.writeQuery({
        query: BulkSyncDocument,
        variables: { id: bulkSync.id },
        data: { bulkSync }
      });
      // update the bulkSyncs list in the cache with
      // new/updated bulkSync to avoid network call
      cache.modify({
        fields: {
          bulkSyncs: (existingRefs: Reference[], { toReference, readField }) => {
            const bulkSyncRef = toReference({
              __typename: 'BulkSync',
              id: bulkSync.id
            });
            if (!existingRefs) {
              return [bulkSyncRef];
            }
            const found = existingRefs.some(ref => readField('id', ref) === bulkSync.id);
            if (found) {
              return existingRefs;
            }
            return [...existingRefs, bulkSyncRef];
          }
        }
      });
      cache.gc();
      setIsDirty(false);
      history.push(generatePath(routes.bulkSyncStatus, { id: bulkSync.id }));
    }
  });
  const handleSchemaData = (data: BulkSourceQuery) => {
    methods.setValue('source.properties', data.bulkSourceForConnection.properties);
    methods.setValue('source.configurationForm', data.bulkSourceForConnection.configurationForm);
    if (!data || !data.bulkSourceForConnection.namespaces) {
      return;
    }
    methods.setValue('source.schemaLabel', data.bulkSourceForConnection.schemaLabel);
    methods.setValue('source.namespaces', data.bulkSourceForConnection.namespaces);
    methods.setValue('source.namespaceLabel', data.bulkSourceForConnection?.namespaceLabel);
  };
  const [refetchSchemas, { loading: refetchSchemasLoading }] = useLazyContinuationQuery<
    BulkSourceQuery,
    BulkSourceQueryVariables
  >(BulkSourceDocument, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      connectionId: methods.getValues('source')?.connection?.id || '',
      continuation: uuid()
    },
    onCompleted: handleSchemaData,
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    }
  });
  const [refetchAll, { loading: refetchAllLoading }] = useLazyContinuationQuery<
    BulkSourceQuery,
    BulkSourceQueryVariables
  >(BulkSourceDocument, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      connectionId: methods.getValues('source')?.connection?.id || '',
      continuation: uuid()
    },
    onCompleted: async data => {
      handleSchemaData(data);
      if (id) {
        await refetchNamespaces({ variables: { id } });
      }
      setRefetchLoading(false);
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    }
  });

  // When refetching namespaces, pass down to the bulk objects stage as a separated prop,
  // that component will merge it's local state then update the form data.
  const [newNamespaces, setNewNamespaces] = React.useState<BulkNamespaceSelection[]>([]);
  const [refetchNamespaces, { loading: namespacesLoading }] = useLazyQuery(BulkSyncDocument, {
    variables: { id },
    fetchPolicy: 'no-cache',
    onCompleted: ({ bulkSync }) => {
      setNewNamespaces(bulkSync.namespaces);
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });
  const refetchSchemasAndNamespaces = async () => {
    setRefetchLoading(true);
    // Setting refresh:true forces the backend to refetch relevant data from the source connection
    await refetchAll({
      variables: {
        connectionId: methods.getValues('source')?.connection?.id || '',
        refresh: true,
        continuation: uuid()
      }
    });
  };

  const refetchSchemasTarget = async (args: BulkSourceQueryVariables) => {
    methods.setValue('namespaces', []);
    if (args.connectionId === sourceId) {
      methods.setValue('namespaces', bulkSync?.namespaces || []);
    }
    setTargetRefetchLoading(true);
    await refetchSchemas({
      variables: args
    });
    setTargetRefetchLoading(false);
  };

  // TODO: extract me from being inline to utils file
  const doValidation = (stage: StageStates) => {
    const localErrors = [];
    if (
      destination?.connection?.type?.operations?.includes(Operation.RequiresPermalink) &&
      !source?.connection?.type?.operations?.includes(Operation.ProvidesPermalink)
    ) {
      localErrors.push(
        `${source.connection.type.name} syncing to ${destination.connection.type.name} unsupported`
      );
    }
    switch (stage) {
      case 'objects':
        if (isNamespaceRoot(selectedNamespaces)) {
          if (selectedNamespaces[0]?.schemas?.length === 0) {
            localErrors.push(
              cx(
                methods.getValues('source.connection.name'),
                `${schemaLabel.plural} cannot be empty`
              )
            );
          }
        } else {
          if (selectedNamespaces.length === 0) {
            localErrors.push(
              cx(
                methods.getValues('source.connection.name'),
                `${schemaLabel.plural} cannot be empty`
              )
            );
          }
        }
        break;
      case 'schedule': {
        const schedule = methods.getValues('schedule');
        if (!schedule?.frequency) {
          localErrors.push('Schedule frequency cannot be empty');
        } else {
          switch (schedule.frequency) {
            case Frequency.Hourly:
              if ((schedule as HourlySchedule)?.minute == null) {
                localErrors.push('Hourly schedule: must specify minutes');
              }
              break;
            case Frequency.Daily: {
              const s = schedule as DailySchedule;
              if (s?.hour == null) {
                localErrors.push('Daily schedule: must specify hour');
              }
              if (s?.minute == null) {
                localErrors.push('Daily schedule: must specify minute');
              }
              break;
            }
            case Frequency.Weekly: {
              const s = schedule as WeeklySchedule;
              if (s.dayOfWeek == null) {
                localErrors.push('Weekly schedule: must specify day');
              }
              if (s.hour == null) {
                localErrors.push('Weekly schedule: must specify hour');
              }
              if (s.minute == null) {
                localErrors.push('Weekly schedule: must specify minute');
              }
              break;
            }
          }
        }
        break;
      }
      case 'summary':
        if (!methods.getValues('name')) {
          localErrors.push('Bulk sync name cannot be empty');
        }
        break;
    }
    return localErrors;
  };
  const handleValidation = () => {
    dispatchBanner({ type: 'hide' });
    const localErrors = doValidation(stage);
    if (hasItems(localErrors)) {
      dispatchBanner({ type: 'show', payload: { message: localErrors, wrapper: wrapperStyles } });
      return;
    }
    // poor man's state machine transitions
    switch (stage) {
      case 'objects':
        setStage('schedule');
        return;
      case 'schedule':
        setStage('summary');
        return;
    }
    const values = Object.assign({}, methods.getValues());
    const sync = prepareBulkSyncUpdate(values);
    // this is the save step
    if (isCreating) {
      void createBulkSync({
        variables: { sync, confirmation, tags: values?.tags?.map(tag => tag.id) || [] }
      });
      return;
    }
    // update bulk sync goes here
    if (!bulkSync) {
      return;
    }
    void updateBulkSync({
      variables: { id: bulkSync?.id, sync, tags: values?.tags?.map(tag => tag.id) || [] }
    });
    return;
  };

  const objectsLoading =
    refetchSchemasLoading || refetchAllLoading || targetRefetchLoading || namespacesLoading;

  return (
    <FormProvider {...methods}>
      <PageLayout
        topNavHeading={isCreating ? 'Create bulk sync' : bulkSync?.name}
        topNavActions={
          <>
            <Button theme="outline" iconEnd="CloseX" onClick={handleCancel}>
              Cancel
            </Button>
            {stage === 'summary' && (
              <EditPermission>
                <Button
                  theme="outline"
                  iconEnd="Check"
                  onClick={() => handleValidation()}
                  loading={createBulkSyncLoading || updateBulkSyncLoading}
                >
                  Save
                </Button>
              </EditPermission>
            )}
          </>
        }
      >
        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            {' '}
            <LoadingDots />{' '}
          </div>
        ) : (
          <div className={cx('animate-fadeIn px-3 pt-6', isCreating ? 'pb-[80vh]' : 'pb-[35vh]')}>
            {(sourceId || isCreating) && (
              <StageBulkTarget
                id={id}
                getSchemas={refetchSchemasTarget}
                setIsDirty={setIsDirty}
                completeModeStage={handleCompleteModeStage}
              />
            )}
            <Dots />
            {showModeStage === 1 && (
              <>
                <StageBulkMode completeModeStage={handleCompleteModeStage} />
                <Dots />
              </>
            )}
            {mode && (
              <>
                <StageBulkObjects
                  namespaceOptions={namespaces}
                  selectedNamespaces={selectedNamespaces}
                  newNamespaces={newNamespaces}
                  setSelectedNamespaces={setSelectedNamespaces}
                  step={2 + showModeStage}
                  loading={objectsLoading}
                  bulkSyncSchemaLabel={schemaLabel}
                  bulkSyncSchemaHeader={namespaceLabel}
                  refreshBtn={
                    <Button
                      loading={refetchLoading}
                      disabled={objectsLoading}
                      iconEnd="Refresh"
                      onClick={() => refetchSchemasAndNamespaces()}
                    >
                      Refresh
                    </Button>
                  }
                />
                <Dots />
              </>
            )}
            {['schedule', 'summary'].includes(stage) && (schedule || isCreating) && (
              <>
                <StageBulkSchedule step={3 + showModeStage} setIsDirty={setIsDirty} />
                <Dots />
              </>
            )}
            {stage === 'summary' && (
              <StageBulkSummary
                step={4 + showModeStage}
                totalSelectedSchemaCount={totalSelections}
              />
            )}
            {isCreating && ['objects', 'schedule'].includes(stage) && (
              <div className="flex w-full justify-center">
                <Button onClick={() => handleValidation()}>Continue</Button>
              </div>
            )}
          </div>
        )}
        <Dialog
          show={!!confirmation}
          heading="Confirm overwrite and save?"
          onDismiss={resetConfirmation}
          size="sm"
          actions={
            <>
              <Button onClick={resetConfirmation}>Cancel</Button>
              <Button
                theme="primary"
                loading={createBulkSyncLoading || updateBulkSyncLoading}
                onClick={handleValidation}
              >
                Overwrite and save
              </Button>
            </>
          }
        >
          <p>{confirmationMessage}</p>
        </Dialog>
        <PromptUnsaved when={isDirty} />
      </PageLayout>
    </FormProvider>
  );
};

export default BulkSyncConfig;
