import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { omit } from 'lodash';
import { FormBuilder, SideBySide } from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { OauthButton } from '../connection-components';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function DropboxConnectionConfig(props: Props) {
  const { control } = useFormContext<ConnectionFormValues>();

  const token = useWatch({ control, name: 'configuration.oauth_token_expiry' });
  const isSingleTable = useWatch({ control, name: 'configuration.is_single_table' });
  const isCSV = useWatch({ control, name: 'configuration.single_table_file_format' }) === 'csv';

  return (
    <SideBySide hasSectionWrap maxWidth="max-w-xs" styles="space-y-3">
      <OauthButton
        isConnected={props.saved || !!props.connection?.saved}
        oauthLoading={props.oauthLoading}
        connectionTypeName={props.connectionType.name}
        onSave={props.onSave}
      />
      {!!token && (
        <>
          <FormBuilder<ConnectionFormValues>
            features={props.features}
            prefix="configuration"
            promiseOptions={props.promiseOptions}
            schema={omit(props.connectionType.configurationSchema, [
              'definitions.ConnectionConf.properties.single_table_name',
              'definitions.ConnectionConf.properties.single_table_file_format',
              'definitions.ConnectionConf.properties.skip_lines'
            ])}
          />
          {isSingleTable && (
            <FormBuilder<ConnectionFormValues>
              features={props.features}
              prefix="configuration"
              promiseOptions={props.promiseOptions}
              schema={omit(props.connectionType.configurationSchema, [
                'definitions.ConnectionConf.properties.is_single_table',
                'definitions.ConnectionConf.properties.bucket',
                'definitions.ConnectionConf.properties.skip_lines'
              ])}
            />
          )}
          {isSingleTable && isCSV && (
            <FormBuilder<ConnectionFormValues>
              features={props.features}
              prefix="configuration"
              promiseOptions={props.promiseOptions}
              schema={omit(props.connectionType.configurationSchema, [
                'definitions.ConnectionConf.properties.is_single_table',
                'definitions.ConnectionConf.properties.bucket',
                'definitions.ConnectionConf.properties.single_table_name',
                'definitions.ConnectionConf.properties.single_table_file_format'
              ])}
            />
          )}
        </>
      )}
    </SideBySide>
  );
}
