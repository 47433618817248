import { API } from './API';
import { CSV } from './CSV';
import { Default } from './Default';
import { Dropbox } from './Dropbox';
import { FBAudience } from './FBAudiance';
import { Gong } from './Gong';
import { GoogleSheets } from './GoogleSheets';
import { Jira } from './Jira';
import { Salesforce } from './Salesforce';
import { Salesloft } from './Salesloft';
import { ZenDesk } from './ZenDesk';
import type { FormBuilderProps } from './types';

export function FormBuilder(props: FormBuilderProps) {
  switch (props.id) {
    case 'api':
      return <API {...props} />;
    case 'csv':
      return <CSV {...props} />;
    case 'fbaudience':
      return <FBAudience {...props} />;
    case 'gsheets':
      return <GoogleSheets {...props} />;
    case 'jira':
      return <Jira />;
    case 'salesforce':
      return <Salesforce {...props} />;
    case 'zendesk_support':
      return <ZenDesk {...props} />;
    case 'salesloft':
      return <Salesloft {...props} />;
    case 'gong':
      return <Gong {...props} />;
    case 'dropbox':
      return <Dropbox {...props} />;
    default:
      return <Default {...props} />;
  }
}
