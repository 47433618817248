import { useFormContext, useWatch } from 'react-hook-form';
import { FormElements } from '~/components';
import { getSchemaAsList } from '~/utils';
import { OAuthMessage } from './OAuthMessage';
import type { FormBuilderProps } from './types';

const singleTableFields = ['single_table_name', 'single_table_file_format'];
const csvFields = ['skip_lines'];

export function Dropbox(props: FormBuilderProps) {
  const { control, register } = useFormContext();
  const token = useWatch({ control, name: 'oauth_token_expiry' });
  const isSingle = useWatch({ control, name: 'is_single_table' });
  const isCSV = useWatch({ control, name: 'single_table_file_format' }) === 'csv';

  const baseItems = getSchemaAsList(props.schema).filter(
    item => ![...singleTableFields, ...csvFields].includes(item.name)
  );
  const singleItems = getSchemaAsList(props.schema).filter(item =>
    singleTableFields.includes(item.name)
  );
  const csvItems = getSchemaAsList(props.schema).filter(item => csvFields.includes(item.name));

  return (
    <>
      {/* Include default values for bucket value */}
      <input {...register('bucket')} type="hidden" defaultValue="" />

      <OAuthMessage {...props} />
      {token && baseItems.map(item => <FormElements key={item.name as string} item={item} />)}
      {isSingle && singleItems.map(item => <FormElements key={item.name as string} item={item} />)}
      {isSingle &&
        isCSV &&
        csvItems.map(item => <FormElements key={item.name as string} item={item} />)}
    </>
  );
}
