import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { VariantProps, cn, getClassNames } from '~/lib/utils';
import { getISOWithoutTimezone } from '~/utils';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { useOnClickOutside } from '~/hooks';
import { capitalize } from 'lodash';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const datePicker = tv({
  slots: {
    wrapper: 'grid gap-2',
    input: `h-8 w-full justify-start rounded border-none py-2 px-3 text-left text-sm font-normal leading-none data-[state=open]:ring-2 data-[state=open]:ring-indigo-500`
  },
  variants: {
    variant: {
      outlined: {
        input: 'bg-white shadow-input ring-1 ring-gray-400 focus:ring-gray-400'
      },
      flat: {
        input:
          'border-gray-50 bg-gray-50 hover:border-indigo-50 hover:bg-indigo-50 data-[state=open]:border-indigo-50 data-[state=open]:bg-indigo-50'
      }
    }
  },
  defaultVariants: {
    variant: 'outlined'
  }
});

const getDateFromText = (text: string) =>
  dayjs(
    capitalize(text?.trim()),
    [
      // ISO
      'YYYY-MM-DD',
      // Short
      'MM-DD-YY',
      'MM-DD-YYYY',
      'MM/DD/YY',
      'MM/DD/YYYY',
      'M-D-YY',
      'M/D/YY',
      'M-D-YYYY',
      'M/D/YYYY',
      // Long with comma
      'MMMM DD, YYYY',
      'MMMM D, YYYY',
      'MMM DD, YYYY',
      'MMM D, YYYY',
      'MMMM DD, YY',
      'MMMM D, YY',
      'MMM DD, YY',
      'MMM D, YY',
      // Long without comma
      'MMMM DD YYYY',
      'MMMM D YYYY',
      'MMM DD YYYY',
      'MMM D YYYY',
      'MMMM DD YY',
      'MMMM D YY',
      'MMM DD YY',
      'MMM D YY',
      // Without year
      'MMMM DD',
      'MMMM D',
      'MMM DD',
      'MMM D',
      'MM DD',
      'MM D',
      'MM/DD',
      'MM-DD',
      'M/D',
      'M-D'
    ],
    true
  );

export interface DatePickerProps extends VariantProps<typeof datePicker> {
  placeholder?: string;
  date?: string;
  onSelect?(date: string): unknown;
}

export function DatePicker({
  date: initialDate,
  placeholder = 'Select a date...',
  onSelect,
  ...rest
}: DatePickerProps) {
  const [date, setDate] = useState<Date>();
  const [text, setText] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [month, setMonth] = useState(new Date());

  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { wrapper, input } = getClassNames(datePicker, rest);

  useEffect(() => {
    setDate(getISOWithoutTimezone(initialDate));
    setText(initialDate ? dayjs(initialDate).format('MMMM D, YYYY') : '');
    setMonth(initialDate ? dayjs(initialDate).toDate() : new Date());
  }, [initialDate, setDate]);

  const handleSelect = (selected?: Date) => {
    if (!selected) {
      setFocused(false);
      return;
    }
    const next = selected?.toISOString().split('T')[0];
    setText(dayjs(selected).format('MMMM D, YYYY'));
    setDate(selected);
    setMonth(selected);
    setFocused(false);
    setHasError(false);
    onSelect?.(next);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    const d = getDateFromText(newText);
    setText(newText);
    if (d.isValid()) {
      setDate(getISOWithoutTimezone(d.toISOString()));
      setMonth(d.toDate());
      setHasError(false);
    }
  };

  const handleBlur = () => {
    const d = getDateFromText(text);
    setFocused(false);
    inputRef.current?.blur();
    if (d.isValid()) {
      setText(dayjs(d).format('MMMM D, YYYY'));
      setDate(getISOWithoutTimezone(d.toISOString()));
      setMonth(d.toDate());
      onSelect?.(d.toISOString().split('T')[0]);
    } else {
      setDate(undefined);
      if (text.length) {
        setHasError(true);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Enter') {
      handleBlur();
    }
  };

  useOnClickOutside([inputRef, popoverRef], () => {
    if (focused) {
      handleBlur();
    }
  });

  return (
    <div className={wrapper} ref={popoverRef}>
      <Popover open={focused}>
        <PopoverTrigger asChild className={cn(input, hasError && 'ring-2 ring-red-500')}>
          <input
            type="text"
            name="date"
            ref={inputRef}
            value={text}
            onChange={handleChange}
            onFocus={() => setFocused(true)}
            //@ts-expect-error KeyboardEvent is okay
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            autoComplete="off"
          />
        </PopoverTrigger>
        <PopoverContent
          ref={popoverRef}
          className="w-auto p-0"
          align="start"
          onOpenAutoFocus={e => e.preventDefault()}
        >
          <Calendar
            mode="single"
            month={month}
            onMonthChange={m => setMonth(m)}
            selected={date}
            onDayClick={handleSelect}
            defaultMonth={date}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
