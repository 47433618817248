import clsx from 'clsx';
import { memo, ReactNode, useMemo } from 'react';

import { Icon } from '~/components';
import { Truncator } from '~/components/truncator';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import { isIconName } from '~/utils';

export type FieldMappingButtonProps = {
  buttonRef?: React.LegacyRef<HTMLButtonElement> | null;
  onFocus?: () => void;
  fieldType: FIELD_MAPPING_TYPE;
  mappingIndex: number;
  selected?: boolean;
  error?: ReactNode;
  disabled?: boolean;
  placeholder?: ReactNode;
  onClick: (t: FIELD_MAPPING_TYPE, idx: number) => void;
  iconId?: string;
  label?: string | undefined | null;
  isNew?: boolean;
  fieldDetail?: ReactNode;
};

export const FieldMappingButton = ({
  buttonRef,
  fieldType,
  mappingIndex,
  selected,
  error,
  disabled = false,
  placeholder,
  onClick,
  iconId,
  label,
  isNew = false,
  fieldDetail
}: FieldMappingButtonProps) => {
  const HOVER_SX = `hover:cursor-pointer hover:bg-indigo-50 hover:border-indigo-50`;
  const ROOT_SX = `min-w-full w-full h-full flex content-start items-center p-1 overflow-clip`;
  const BUTTON_SX = useMemo(
    () =>
      clsx(
        'flex w-full overflow-clip rounded border py-1.25 px-2',
        'outline-none focus:outline-none focus:ring-2 focus:ring-indigo-500',
        error && 'ring-1 ring-red-500',
        disabled && 'invisible',
        selected && 'border-indigo-100 bg-indigo-100',
        !disabled && !selected && label && 'border-gray-50 bg-gray-50',
        !disabled && !selected && !label && 'border-gray-50 bg-gray-50 text-gray-500',
        !disabled && !selected && HOVER_SX
      ),
    [HOVER_SX, disabled, error, label, selected]
  );
  const CONTENT_SX = clsx('flex h-full w-full items-center space-x-2', disabled && 'invisible');

  return (
    <div className={ROOT_SX}>
      <div className="flex h-full w-full flex-col">
        <button
          ref={buttonRef}
          id="FieldMappingButton"
          className={BUTTON_SX}
          onClick={() => !disabled && onClick(fieldType, mappingIndex)}
        >
          <div className={CONTENT_SX}>
            <FieldMappingButtonIcon iconId={iconId} label={label} />
            <Truncator content={label || placeholder}>
              <span className="overflow-clip truncate text-sm">{label || placeholder}</span>
            </Truncator>
            <Chip color={COLOR.SUCCESS} enableHighlight hidden={!isNew} bold>
              New
            </Chip>
          </div>
        </button>
        {error && <p className="mt-1 text-xs font-medium text-red-500">{error}</p>}
        {fieldDetail}
      </div>
    </div>
  );
};

const FieldMappingButtonIcon = memo<Partial<FieldMappingButtonProps>>(({ iconId, label }) => {
  const getIcon = () => {
    if (isIconName(iconId)) {
      return <Icon match={iconId} className="h-4 w-4 text-gray-500" />;
    }
    return null;
  };

  if (!label) return null;

  return getIcon() || <Icon name="TypeString" className="h-4 w-4 text-gray-500" />;
});

export default FieldMappingButton;
