import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Icon, Tooltip } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import { DashboardDocument } from '~/generated/graphql';
import { cn } from '~/lib/utils';
import { plural, getShortLocalTime, routes } from '~/utils';

const vids = [
  {
    name: 'Sync Salesforce to Snowflake',
    href: 'https://www.loom.com/share/2a79b97cb3184283b341e24f16125f2d?sid=4b7207da-a5c3-48b4-80e2-db58c70476ee',
    duration: '3:00'
  },
  {
    name: 'Sync SQL to Salesforce',
    href: 'https://www.loom.com/share/b3bb325044e0452e9a500a5cd1a41e7c?sid=52b525a5-4ef2-42a7-865f-8e45d795ddfa',
    duration: '5:00'
  },
  {
    name: 'Sync HubSpot to BigQuery',
    href: 'https://www.loom.com/share/2815170135b741f6a5e632aad91f1792?sid=a118bee2-1478-4629-8e91-5d49db235dcb',
    duration: '2:35'
  },
  {
    name: 'Sync HubSpot to Zendesk',
    href: 'https://www.loom.com/share/248ef338ce7a4f468bee972e102f7d22?sid=ecfd79cd-5a81-4e85-9483-f761ae400977',
    duration: '2:44'
  }
];

interface StatusCardProps {
  id: string;
  name: string;
  failed: boolean;
  count: number;
  total: number;
  timestamp: string;
  isModelSync: boolean;
  message?: string;
}

function StatusCard({
  id,
  name,
  failed,
  count,
  total,
  timestamp,
  isModelSync,
  message
}: StatusCardProps) {
  return (
    <Tooltip content={message} disabled={isEmpty(message)}>
      <Link
        to={generatePath(isModelSync ? routes.syncHistory : routes.bulkSyncHistory, { id })}
        className="space-between relative flex h-full flex-col gap-2 rounded border-2 border-transparent bg-white p-3 shadow hover:border-indigo-500 hover:bg-indigo-50"
        target="_blank"
      >
        <div
          className={cn(
            'absolute -top-[2px] right-4 rounded-b p-1.5 pt-2',
            failed ? 'bg-red-500' : 'bg-amber-500'
          )}
        >
          <Icon name={failed ? 'DangerFilled' : 'WarningFilled'} className="text-white" size="sm" />
        </div>
        <div className="flex flex-col gap-2">
          <p className={cn('font-medium', failed ? 'text-red-500' : 'text-amber-500')}>
            {failed ? 'Failed' : 'Partially failed'}
          </p>
          <div className="min-h-[60px]">
            <p className="break-words font-medium">{name}</p>
            {failed ? (
              <p>&nbsp;</p>
            ) : (
              <p>{`${count?.toLocaleString()} ${plural(isModelSync ? 'record' : 'table', count !== 1)} failed (${Math.ceil((100 * count) / total)}%)`}</p>
            )}
          </div>
        </div>
        <p className="bottom-0 text-gray-400">{getShortLocalTime(timestamp)}</p>
      </Link>
    </Tooltip>
  );
}

export function Dashboard() {
  const { data, loading, error } = useQuery(DashboardDocument, {
    fetchPolicy: 'network-only'
  });
  const { completed, errored, failed, modelSync, bulkSync } = data?.dashboard ?? {};

  const title = useMemo(() => {
    const count = errored + failed;
    if (count) {
      return `${count} ${plural('sync', count !== 1)} unsuccessful`;
    }
    if (completed) {
      return 'Sync status normal';
    }
    return 'Sync status';
  }, [data]);

  if (!!error) {
    return (
      <EmptyPage
        loading={false}
        message={error?.message ?? 'An error occurred while loading the dashboard.'}
        error={true}
      />
    );
  }

  return (
    <PageLayout topNavHeading="Dashboard" loading={loading}>
      <div className="mx-auto flex w-full max-w-4xl flex-col gap-4 p-6 text-start">
        {/* Status card */}
        <div className="flex flex-col gap-2 rounded-lg bg-white p-6 shadow">
          <h2 className="text-3xl font-bold">{title}</h2>
          <div className="flex justify-evenly gap-6">
            {/* Completed */}
            <div className="flex-1 rounded border py-4 px-6">
              <span
                className={cn('text-6xl font-bold text-gray-500', completed && 'text-green-500')}
              >
                {completed ?? '0'}
              </span>
              <p className="text-gray-500">Completed</p>
            </div>
            {/* Failed */}
            <div
              className={cn(
                'flex-1 rounded py-4 px-6',
                failed ? 'bg-red-500 text-white hover:bg-red-600' : 'border text-gray-500'
              )}
            >
              <span className={cn('text-6xl font-bold')}>{failed ?? '0'}</span>
              <p>Failed</p>
            </div>
            {/* Errored */}
            <div
              className={cn(
                'flex-1 rounded py-4 px-6',
                errored ? 'bg-amber-500 text-white hover:bg-amber-600' : 'border text-gray-500'
              )}
            >
              <span className={cn('text-6xl font-bold')}>{errored ?? '0'}</span>
              <p>Partially failed</p>
            </div>
          </div>
          {!(completed || failed || errored) && (
            <p className="pt-4 text-gray-500">No syncs have run yet.</p>
          )}
          {!!completed && !failed && !errored && (
            <div className="flex gap-2 pt-4">
              <Icon name="CheckFilled" className="text-green-500" />
              <p className="text-gray-500">Everything looks good!</p>
            </div>
          )}
        </div>
        {/* Details */}
        {!!modelSync?.length && (
          <div className="mx-auto flex w-full flex-col gap-2">
            <h4 className="label font-medium text-gray-500">Model syncs</h4>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              {[...modelSync]
                .sort((a, b) => b.completedAt.localeCompare(a.completedAt))
                .map(sync => (
                  <StatusCard
                    key={sync.id}
                    id={sync.id}
                    name={sync.name}
                    failed={sync.failed}
                    count={sync.failedRecords}
                    total={sync.totalRecords}
                    timestamp={sync.completedAt}
                    isModelSync={true}
                    message={sync.errorMessage}
                  />
                ))}
            </div>
          </div>
        )}
        {!!bulkSync?.length && (
          <div className="mx-auto flex w-full flex-col gap-2">
            <h4 className="label font-medium text-gray-500">Bulk syncs</h4>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              {[...bulkSync]
                .sort((a, b) => b.lastCompletedAt.localeCompare(a.lastCompletedAt))
                .map(sync => (
                  <StatusCard
                    key={sync.id}
                    id={sync.id}
                    name={sync.name}
                    failed={sync.failedSchemas === sync.totalSchemas}
                    count={sync.failedSchemas}
                    total={sync.totalSchemas}
                    timestamp={sync.lastCompletedAt}
                    isModelSync={false}
                  />
                ))}
            </div>
          </div>
        )}

        {/* Onboarding */}
        {!(completed || failed || errored) && (
          <div className="mx-auto w-full px-6 text-start">
            <h2 className="my-4 text-2xl font-bold">Creating your first sync</h2>
            <div className="mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
              <a
                href="https://docs.polytomic.com/docs/bulk-syncs"
                target="_blank"
                rel="noreferrer"
                className="rounded-lg bg-indigo-600 p-6 text-white hover:bg-indigo-900"
              >
                <Icon name="Truck" className="h-10 w-10 text-indigo-400" />
                <h3 className="text-md my-2 font-bold">Syncing to a data warehouse?</h3>
                <ol className="list-inside list-decimal">
                  <li>Add connections</li>
                  <li>Create a Bulk Sync</li>
                </ol>
              </a>
              <a
                href="https://docs.polytomic.com/docs/model-syncs"
                target="_blank"
                rel="noreferrer"
                className="rounded-lg bg-indigo-600 p-6 text-white hover:bg-indigo-900"
              >
                <Icon name="Syncs" className="h-10 w-10 text-indigo-400" />
                <h3 className="text-md my-2 font-bold">Syncing to a SaaS tool?</h3>
                <ol className="list-inside list-decimal">
                  <li>Add connections</li>
                  <li>Create models</li>
                  <li>Create a Model Sync</li>
                </ol>
              </a>
              <a
                href="https://apidocs.polytomic.com/guides/introduction"
                target="_blank"
                rel="noreferrer"
                className="rounded-lg bg-indigo-600 p-6 text-white hover:bg-indigo-900"
              >
                <Icon name="Api2" className="h-10 w-10 text-indigo-400" />
                <h3 className="text-md my-2 font-bold">Looking for our sync API?</h3>
                <ol className="list-inside list-decimal">
                  <li>Generate an API key</li>
                  <li>Read our API documentation</li>
                </ol>
              </a>
            </div>
            <h2 className="my-4 text-2xl font-bold">Example quickstart videos</h2>
            <div className="flex flex-col gap-2">
              {vids.map(vid => (
                <a
                  key={vid.name}
                  href={vid.href}
                  target="_blank"
                  rel="noreferrer"
                  className="group inline-flex w-fit items-center gap-2 rounded bg-white py-1.5 px-2 shadow-sm hover:bg-blue-50 hover:text-blue-500"
                >
                  <Icon name="Video" />
                  <span>{vid.name}</span>
                  <span className="text-gray-400 group-hover:text-blue-500">{vid.duration}</span>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
}
