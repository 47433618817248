import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  FormBuilder,
  MyCombobox,
  Checkbox,
  MyInput,
  SideBySide
} from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';
import { OauthButton } from '../connection-components';
import { omit } from 'lodash';

const ZENDESK_AUTH_METHODS = {
  OAUTH: { label: 'OAuth', value: 'oauth' },
  TOKEN: { label: 'Token', value: 'apitoken' }
};

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function ZendeskConnectionConfig(props: Props) {
  const {
    connection,
    connectionType,
    features,
    oauthLoading,
    saved,
    promiseOptions,
    onSave,
    setConnectionType
  } = props;

  const { control, setValue, register, getValues, formState } =
    useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const authMethods =
    (connectionType?.configurationSchema?.definitions as any)?.ConnectionConf?.properties
      ?.auth_method?.enum ?? ZENDESK_AUTH_METHODS;

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_method');
    return authMethods.find(m => m.value === auth) ?? authMethods[0];
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);
  const token = useWatch({ control, name: 'configuration.api_token' });
  const email = useWatch({ control, name: 'configuration.email' });
  const domain = useWatch({ control, name: 'configuration.domain' });

  const customApiLimit = getValues('configuration.custom_api_limits');

  const [checked, setChecked] = React.useState(false);

  const handleClick = () => {
    if (checked) {
      setChecked(false);
      setValue('configuration.custom_api_limits', false);
    }
    if (!checked) {
      setChecked(true);
      setValue('configuration.custom_api_limits', true);
    }
  };

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_method', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
      setConnectionType({ ...connectionType, useOAuth: option.value === 'oauth' });
    }
  };

  // Set checked if custom_api_limits is true
  React.useEffect(() => {
    if (customApiLimit) {
      setChecked(true);
    }
  }, [customApiLimit]);

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={authMethods}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      {authMethod && (
        <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.domain', {
                  required: isRequiredMsg(fields?.['domain']?.title)
                })}
                label={fields?.['domain']?.title}
                description={fields?.['domain']?.description}
                errors={errors}
              />
            </EditPermission>
            {authMethod?.value === ZENDESK_AUTH_METHODS.TOKEN.value && (
              <div className="max-w-xs">
                <EditPermission>
                  <div className="mt-3">
                    <MyInput
                      {...register('configuration.email', {
                        required: isRequiredMsg(fields?.['email']?.title)
                      })}
                      label={fields?.['email']?.title}
                      description={fields?.['email']?.description}
                      errors={errors}
                    />
                  </div>
                </EditPermission>
                <EditPermission>
                  <div className="mt-3">
                    <MyInput
                      {...register('configuration.api_token', {
                        required: isRequiredMsg(fields?.['api_token']?.title)
                      })}
                      label={fields?.['api_token']?.title}
                      description={fields?.['api_token']?.description}
                      errors={errors}
                    />
                  </div>
                </EditPermission>
              </div>
            )}
            <div className="max-w-xs">
              <EditPermission>
                <div className="mt-3">
                  <Checkbox
                    {...register('configuration.custom_api_limits')}
                    label={fields?.['custom_api_limits']?.title}
                    description={fields?.['custom_api_limits']?.description}
                    errors={errors}
                    onChange={handleClick}
                    checked={checked}
                  />
                </div>
              </EditPermission>
            </div>
            <div className="max-w-xs">
              {checked && (
                <EditPermission>
                  <div className="mt-3">
                    <MyInput
                      {...register('configuration.ratelimit_rpm', {
                        required: isRequiredMsg(fields?.['ratelimit_rpm']?.title)
                      })}
                      label={fields?.['ratelimit_rpm']?.title}
                      description={fields?.['ratelimit_rpm']?.description}
                      errors={errors}
                    />
                  </div>
                </EditPermission>
              )}
            </div>
          </div>
          {authMethod?.value === ZENDESK_AUTH_METHODS.OAUTH.value && (
            <div className="max-w-xs">
              <OauthButton
                isConnected={
                  saved ||
                  (!!connection?.saved && authMethod?.value === ZENDESK_AUTH_METHODS.OAUTH.value)
                }
                oauthLoading={oauthLoading}
                connectionTypeName={connectionType.name}
                onSave={onSave}
              />
            </div>
          )}
          {((token && email) || connection?.saved) && domain && (
            <FormBuilder<ConnectionFormValues>
              features={features}
              schema={omit(props.connectionType.configurationSchema, [
                'definitions.ConnectionConf.properties.auth_method',
                'definitions.ConnectionConf.properties.api_token',
                'definitions.ConnectionConf.properties.email',
                'definitions.ConnectionConf.properties.domain',
                'definitions.ConnectionConf.properties.custom_api_limits',
                'definitions.ConnectionConf.properties.ratelimit_rpm'
              ])}
              prefix="configuration"
              promiseOptions={promiseOptions}
            />
          )}
        </SideBySide>
      )}
    </>
  );
}
