import cx from 'clsx';
import qs from 'query-string';
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';
import { FrequencyOptions, Icon } from '~/components';
import { Truncator } from '../../../components';
import { BulkExecutionStatus, BulkSyncIndexFragment } from '../../../generated/graphql';
import { addLineBreaks, capsFirst, getShortLocalTime, plural, routes } from '../../../utils';
import { memo, useCallback, useEffect, useRef } from 'react';
import { Status } from '~/components/v3';

const sharedStyles =
  'min-h-[3.75rem] flex px-4 border-t border-gray-300 group-hover:bg-indigo-50 h-full';

export const BulkSyncRow = memo<{
  bulkSync: BulkSyncIndexFragment;
}>(({ bulkSync }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { search } = useLocation();

  const health = bulkSync.destination?.connection?.health.status === 'healthy' || true;

  const amendHistory = useCallback(() => {
    history.replace({ search: `id=${bulkSync.id}` });
  }, [bulkSync.id, history]);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    const parsed = qs.parse(search);
    if (parsed.id === bulkSync.id) {
      history.replace({ search: undefined });
      scrollRef.current.scrollIntoView({ block: 'center' });
    }
  }, []);

  return (
    <div className="group contents">
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div ref={scrollRef} className={cx('flex-col justify-center', sharedStyles)}>
          <span className="text-sm font-medium text-gray-800">{addLineBreaks(bulkSync.name)}</span>
          <span className="mt-0.5 text-xs text-gray-500">
            Last run: {getShortLocalTime(bulkSync.execution?.startedAt) || 'Never'}
          </span>
        </div>
      </BulkSyncLink>
      {!health ? (
        bulkSync.destination?.connection?.id && (
          <RowSyncIconsWrapper id={bulkSync.source?.connection?.type?.id}>
            <Link
              to={generatePath(routes.editConnection, {
                id: bulkSync.destination.connection.id
              })}
              onClick={amendHistory}
              className="mx-2 break-words text-red-500 underline hover:no-underline"
            >
              Unhealthy connection
            </Link>
          </RowSyncIconsWrapper>
        )
      ) : (
        <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
          <RowSyncIconsWrapper id={bulkSync.source?.connection?.type?.id}>
            <Icon name="Syncs" className="mx-2 text-gray-600" />
            {bulkSync.destination?.connection?.type.id && (
              <Icon match={bulkSync.destination?.connection.type.id} className="mr-1.5" />
            )}
            <Truncator
              content={
                bulkSync.destination?.connection
                  ? bulkSync.destination?.connection?.name
                  : 'Missing target object'
              }
            >
              <p
                className={cx(
                  'truncate whitespace-nowrap text-sm',
                  bulkSync.destination?.connection ? 'text-gray-800' : 'text-red-500'
                )}
              >
                {bulkSync.destination?.connection
                  ? bulkSync.destination?.connection?.name
                  : 'Missing target object'}
              </p>
            </Truncator>
          </RowSyncIconsWrapper>
        </BulkSyncLink>
      )}
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div className={cx('items-center text-sm text-gray-800', sharedStyles)}>
          {FrequencyOptions[bulkSync.schedule?.frequency]?.label}
        </div>
      </BulkSyncLink>
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div className={cx('items-center', sharedStyles)}>
          <BulkSyncStatus bulkSync={bulkSync} />
        </div>
      </BulkSyncLink>
    </div>
  );
});

const BulkSyncLink = (props: { id: string; children: React.ReactNode; onClick: () => void }) => {
  return (
    <Link to={generatePath(routes.bulkSyncStatus, { id: props.id })} onClick={props.onClick}>
      {props.children}
    </Link>
  );
};

const RowSyncIconsWrapper = ({
  children,
  id
}: {
  id: string | undefined;
  children: React.ReactNode;
}) => {
  return (
    <div className={cx('items-center truncate', sharedStyles)}>
      <Icon match={id} />
      {children}
    </div>
  );
};

const BulkSyncStatus = ({ bulkSync }: { bulkSync: BulkSyncIndexFragment }) => {
  if (!bulkSync.active && (!bulkSync.activeExecutions || bulkSync.activeExecutions?.length === 0)) {
    return (
      <Status icon="Disabled" classNames={{ icon: 'text-gray-400', text: 'text-gray-400' }}>
        Disabled
      </Status>
    );
  }
  if (!bulkSync.execution) {
    return <p className="text-sm text-gray-400">Never run</p>;
  }
  if (bulkSync?.activeExecutions?.length > 0) {
    switch (bulkSync.activeExecutions[0].status) {
      case BulkExecutionStatus.Created:
        return <Status icon="DotsH">Starting</Status>;
      case BulkExecutionStatus.Running:
      case BulkExecutionStatus.Processing:
        return (
          <Status icon="ArrowRightFilled" classNames={{ icon: 'text-blue-500 ' }}>
            Running
          </Status>
        );
      case BulkExecutionStatus.Exporting:
        return <Status icon="DotsH">Exporting</Status>;
      default:
        return <p className="text-sm">{capsFirst(bulkSync.execution.status)}</p>;
    }
  }
  switch (bulkSync.execution.status) {
    case BulkExecutionStatus.Errors:
      const errorCount = bulkSync.execution.errorCount;
      return (
        <Status
          icon="WarningFilled"
          classNames={{ icon: 'text-amber-500 ' }}
          tooltipContent={
            bulkSync.execution.statusMessage ||
            `${errorCount?.toLocaleString()} ${plural('error', (errorCount || 0) > 1)}`
          }
        >
          <Link className="link" to={generatePath(routes.bulkSyncHistory, { id: bulkSync.id })}>
            Completed
          </Link>
        </Status>
      );
    case BulkExecutionStatus.Completed:
      return (
        <Status icon="CheckFilled" classNames={{ icon: 'text-green-500 ' }}>
          Completed
        </Status>
      );
    case BulkExecutionStatus.Failed:
      return (
        <Status
          icon="DangerFilled"
          classNames={{ icon: 'text-red-500 ' }}
          tooltipContent={bulkSync.execution.statusMessage || 'Bulk sync failed'}
        >
          <Link className="link" to={generatePath(routes.bulkSyncHistory, { id: bulkSync.id })}>
            Failed
          </Link>
        </Status>
      );
    default:
      return <p className="text-sm">{capsFirst(bulkSync.execution.status)}</p>;
  }
};
