import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Icon } from '~/components';
import { Alert } from '~/components/v3';
import { getSchemaAsList } from '~/utils';
import { Button } from './Button';
import { Footer } from './Footer';
import { FormBuilder } from './FormBuilder';
import { Spinner } from './Spinner';

type Config = Record<string, unknown>;
interface Props {
  connection: {
    configuration?: Config;
    statusError?: string;
  };
  connectionType: {
    id: string;
    devConfig?: Config;
    name: string;
    schema: Config;
    useOAuth?: boolean;
  };
  isConnecting?: boolean;
  onCancel(): unknown;
  onConnect(config: Config): unknown;
}

/**
 * Strips the readonly fields from the configuration after submitting. It's a
 * bit of a hack to put it here and not to exclude the fields from being
 * submitted by <FormBuilder /> but, I don't know what effect it would have
 * from exluding the fields in the rest of the app, so, including it here for
 * now.
 **/
function stripReadOnly(schema: Config, next: (config: Config) => unknown) {
  return (config: Record<string, unknown>) => {
    const properties = [
      ...getSchemaAsList(schema),
      // HACK: allow forcing a OAuth reconnect
      { name: '__reconnect' }
    ];
    const stripped: Record<string, unknown> = {};
    for (const prop of properties) {
      if (prop.readonly || config[prop.name] === undefined) {
        // Don't include the field if it's readonly or if it's undefined.
      } else {
        stripped[prop.name] = config[prop.name];
      }
    }
    next(stripped);
  };
}

export function Connect(props: Props) {
  const methods = useForm<Record<string, unknown>>();
  const handleSubmit = methods.handleSubmit(
    stripReadOnly(props.connectionType.schema, props.onConnect)
  );

  useEffect(() => {
    methods.reset(props.connection.configuration || {});
  }, [props.connection.configuration]);

  return (
    <>
      {props.connection.statusError && (
        <Alert
          isCloseable
          classNames={{
            container: 'sticky top-0 z-10'
          }}
          variant="warning"
        >
          {props.connection.statusError}
        </Alert>
      )}
      <header className="relative flex shrink-0 flex-col items-start justify-start gap-2">
        <Icon match={props.connectionType.id} className="h-10 w-10" />
        <p className="font-semibold leading-5 text-gray-800">
          {props.isConnecting ? 'Connecting...' : `Connect to ${props.connectionType.name}`}
          {props.isConnecting && <Spinner />}
        </p>
      </header>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} className=" space-y-4 ">
          <FormBuilder {...props.connectionType} />
        </form>
      </FormProvider>
      <Footer label={props.isConnecting && 'Please wait...'}>
        <Button disabled={props.isConnecting} variant="link" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button disabled={props.isConnecting} onClick={handleSubmit}>
          {props.isConnecting ? 'Connecting' : 'Connect'}
        </Button>
      </Footer>
    </>
  );
}
