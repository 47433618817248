import { useFormContext, useWatch } from 'react-hook-form';
import { FormElements, Label, MyCombobox } from '~/components';
import { getSchemaAsList } from '~/utils';
import { OAuthMessage } from './OAuthMessage';
import type { FormBuilderProps } from './types';

const Options = {
  oauth: {
    label: 'OAuth',
    value: 'oauth',
    fields: []
  },
  token: {
    label: 'Access key and secret',
    value: 'token',
    fields: ['access_key', 'access_secret', 'subdomain']
  }
};
const DefaultOption = Options['oauth'];
const AuthMethodKey = 'auth_method';

export function Gong(props: FormBuilderProps) {
  const { control, setValue, register } = useFormContext();
  const auth = useWatch({ control, name: AuthMethodKey });
  const selected = Options[auth] || DefaultOption;

  const items = getSchemaAsList(props.schema).filter(item =>
    selected.fields.includes(item.name as string)
  );

  const handleSelect = (selected: { label: string; value: string; fields: string[] }) => {
    setValue(AuthMethodKey, selected.value);
  };

  return (
    <>
      <div>
        <Label>Authentication method</Label>
        <MyCombobox options={Object.values(Options)} value={selected} onChange={handleSelect} />
        <input {...register(AuthMethodKey)} type="hidden" value={selected.value} />
      </div>
      {selected.value === 'oauth' && <OAuthMessage {...props} />}
      {items.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
    </>
  );
}
