import Form from '@rjsf/core';
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  FieldTemplateProps,
  ObjectFieldTemplateProps,
  RJSFSchema,
  RegistryWidgetsType,
  TemplatesType,
  UiSchema,
  WidgetProps
} from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from 'ajv/dist/2020';
import { createContext, useContext, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { cn } from '~/lib/utils';
import { Selectable, isRequiredMsg } from '~/utils';
import { EditPermission } from './edit-permission';
import {
  Checkbox,
  DisabledSelect,
  Label,
  LinkButton,
  MyCombobox,
  MyInput,
  ParamButton
} from './form-components';
import { DatePicker, FileUpload } from './v3';

interface FormContext {
  classNames: {
    input: string;
  };
}

const FormLoadingContext = createContext<boolean>(false);

const stripLeadingPeriod = a => a.replace(/^\./, '');

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    control,
    name: stripLeadingPeriod(props.idSchema.$id)
  });

  const onAddIndexClick = (index: number) => () => {
    props.onAddClick();
    append({}, { focusIndex: index + 1 });
  };

  return (
    <div className="flex flex-col gap-2">
      <Label className="col-span-full block font-medium leading-none">{props.title}</Label>
      {props.items.map(element =>
        ArrayFieldItemTemplate({
          ...element,
          onAddIndexClick: onAddIndexClick,
          remove,
          uiSchema: props.uiSchema
        })
      )}
      {props.canAdd && !props.items.length && (
        <EditPermission>
          <ParamButton
            action="add"
            aria-label="add-array-item"
            className="focus-visible:ring-offset-gray-100"
            onClick={onAddIndexClick(0)}
          />
        </EditPermission>
      )}
    </div>
  );
};

interface ArrayFieldItemTemplateProps extends ArrayFieldTemplateItemType {
  remove: (index: number) => void;
}

function ArrayFieldItemTemplate(props: ArrayFieldItemTemplateProps) {
  const { children, remove, className } = props;

  return (
    <div className="flex items-end gap-2" key={props.key}>
      <div className={className}>{children}</div>
      {props.hasRemove && (
        <EditPermission>
          <div className="my-2 flex gap-2">
            <ParamButton
              className={cn(!props.hasRemove && 'hidden')}
              action="delete"
              aria-label="delete-array-item"
              onClick={() => {
                props.onDropIndexClick(props.index)();
                remove(props.index);
              }}
            />
            <ParamButton
              action="add"
              aria-label="add-array-item"
              onClick={props.onAddIndexClick(props.index)}
              hidden={props.index !== props.totalItems - 1}
            />
          </div>
        </EditPermission>
      )}
    </div>
  );
}

const FieldTemplate = (props: FieldTemplateProps) => props.children;

const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <fieldset className={cn('space-y-3', props.uiSchema?.['ui:options']?.['ui:classNames'])}>
      {props.properties.map(element => element.content)}
    </fieldset>
  );
};

export const DefaultTemplates: Partial<TemplatesType> = {
  ArrayFieldTemplate,
  FieldTemplate,
  ObjectFieldTemplate
};

const CheckboxWidget = (props: WidgetProps) => (
  <div className={cn(props.className, props.uiSchema?.['ui:options']?.['ui:classNames'])}>
    <EditPermission>
      <Checkbox
        id={props.id}
        defaultChecked={props.value}
        label={props.label}
        required={props.required}
        onChange={e => props.onChange(e.target.checked)}
      />
    </EditPermission>
  </div>
);

// dependsOn is a higher order function that checks the dependsOn property from
// a fields uiSchema and sets the field to disabled if the dependsOn fields are
// not populated.
function dependsOn<T extends FieldValues>(
  child: (props: WidgetProps<T>) => JSX.Element
): (props: WidgetProps<T>) => JSX.Element {
  return (props: WidgetProps<T>) => {
    const dependsOn = String(props.uiSchema?.['ui:options']?.['dependsOn'] || '')
      .split(';')
      .filter((field: string) => field.length > 0);

    const [disabled, setDisabled] = useState<boolean>(props.disabled || dependsOn.length > 0);
    useEffect(() => {
      setDisabled(!dependsOn.every(field => props.formContext?.formData?.[field]));
    }, [props.formContext?.formData]);

    return child({
      ...props,
      disabled
    });
  };
}

function SelectWidget<T extends FieldValues>({ disabled, ...props }: WidgetProps<T>) {
  const formLoading = useContext(FormLoadingContext);
  const [isAsync, setIsAsync] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState(
    (props.schema.enum as (string | Selectable)[])?.map(option => {
      if (typeof option === 'object') {
        return {
          label: option.label || option.value,
          value: option.value
        };
      }
      return { label: option, value: option };
    }) || []
  );

  useEffect(() => {
    const fetch = async () => {
      const data = await props.formContext.fetchOptions(props.name, '');
      setOptions(data ?? []);
      setIsLoading(false);
    };
    if (
      !disabled &&
      props.uiSchema?.['ui:options']?.['fetchOptions'] &&
      props.formContext.fetchOptions
    ) {
      setIsAsync(true);
      setIsLoading(true);
      fetch();
    }
  }, [disabled]);

  const value =
    options?.find(option => option.value === props.value) ||
    options?.find(option => option.value === '');

  return (
    <div className={cn(props.className, props.uiSchema?.['ui:options']?.['ui:classNames'])}>
      <div className="flex justify-between">
        <Label htmlFor={props.id}>{props.label}</Label>
        {props.uiSchema?.['ui:options']?.['refreshable'] && props.formContext?.refreshForm && (
          <LinkButton
            tabIndex={-1}
            disabled={false}
            onClick={props.formContext?.refreshForm}
            className="mb-1 self-end text-xs"
          >
            Refresh
          </LinkButton>
        )}
      </div>
      <div className={(props.formContext as FormContext)?.classNames?.input}>
        <EditPermission fallback={<DisabledSelect valueLabel={value?.label} />}>
          <MyCombobox
            aria-label={props.label}
            id={props.id}
            value={value}
            options={options}
            disabled={disabled}
            onChange={e => props.onChange(e?.value || e)}
            isClearable={!props.required}
            isAsync={isAsync}
            isLoading={formLoading || isLoading}
          />
        </EditPermission>
      </div>
    </div>
  );
}

function TextWidget<T extends FieldValues>(props: WidgetProps<T>) {
  if (props.readonly && formValue(props.value, '__EMPTY_VALUE__') === '__EMPTY_VALUE__') {
    return null;
  }
  const { formState, register } = useFormContext<T>();

  const { onChange, ...formProps } = register(stripLeadingPeriod(props.id), {
    required: props.required && isRequiredMsg(props.title)
  });

  const suffix = props.uiSchema?.['ui:options']?.suffix;

  return (
    <div
      className={cn(
        'w-full',
        props.uiSchema?.['ui:options']?.['ui:classNames'],
        (props.formContext as FormContext)?.classNames?.input
      )}
    >
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      <div className={cn('flex items-center gap-2')}>
        <div className={cn('flex-1', suffix == 'days' && 'flex-none')}>
          <EditPermission>
            <MyInput
              aria-label={props.label}
              name={props.name}
              className={cn(suffix == 'days' && 'max-w-[60px]', props.className)}
              required={props.required}
              defaultValue={formValue(props.value, props.schema?.default) as string}
              errors={formState.errors ?? props.rawErrors}
              description={props.schema?.description}
              placeholder={
                props.placeholder || (props.uiSchema?.['ui:options']?.['ui:placeholder'] as string)
              }
              readOnly={props.readonly}
              {...formProps}
              onBlur={e => {
                if ((e.relatedTarget as HTMLElement)?.innerText === 'Cancel') {
                  return;
                }
                onChange(e);
                props.onChange(e.target.value);
              }}
            />
          </EditPermission>
        </div>
        {suffix && <p className="my-1">{suffix}</p>}
      </div>
    </div>
  );
}

const FileWidget = function (props: WidgetProps) {
  const { formState, register } = useFormContext();

  const { onChange, ...formProps } = register(stripLeadingPeriod(props.id), {
    required: props.required && !props.value && isRequiredMsg(props.title)
  });

  return (
    <EditPermission>
      <FileUpload
        name={props.name}
        label={props.label}
        value={props.value}
        errors={formState.errors ?? props.rawErrors}
        {...formProps}
        onChange={async e => {
          onChange(e);
          props.onChange(await e.target.files[0].text());
        }}
      />
    </EditPermission>
  );
};

const DateWidget = function (props: WidgetProps) {
  return (
    <div className={cn(props.className, props.uiSchema?.['ui:options']?.['ui:classNames'])}>
      <div className={(props.formContext as FormContext)?.classNames?.input}>
        <Label>{props.label}</Label>
        <EditPermission>
          <DatePicker
            onSelect={date => props.onChange(date)}
            placeholder="Select a date..."
            date={props.value}
          />
        </EditPermission>
      </div>
    </div>
  );
};

export const DefaultWidgets: RegistryWidgetsType = {
  CheckboxWidget,
  SelectWidget: dependsOn(SelectWidget),
  TextWidget,
  FileWidget,
  DateWidget
};

export interface JSONSchemaFormProps<T extends FieldValues> {
  formData?: T;
  schema: RJSFSchema;
  templates?: Partial<TemplatesType>;
  uiSchema: UiSchema;
  widgets?: RegistryWidgetsType;
  formContext?: FormContext;

  loading?: boolean;
  onChange?: (value: T) => unknown;
  refreshForm?: () => void;
  fetchOptions?: (field: string, query?: string) => Promise<Selectable[]>;
}

export function JSONSchemaForm<T extends FieldValues>(props: JSONSchemaFormProps<T>) {
  const {
    formData,
    schema,
    templates = DefaultTemplates,
    uiSchema,
    widgets = DefaultWidgets,
    formContext,
    onChange,
    loading,
    fetchOptions
  } = props;

  const newSchema = {
    ...schema,
    dependencies: { ...(schema.dependentSchemas || schema.dependencies) }
  } as RJSFSchema;

  // Use parent form context if available, otherwise create one
  const methods = useFormContext() ?? useForm();

  return (
    <FormLoadingContext.Provider value={loading}>
      <FormProvider {...methods}>
        <Form
          tagName="div"
          formData={formData}
          schema={newSchema}
          templates={templates}
          uiSchema={{
            'ui:submitButtonOptions': {
              norender: true
            },
            ...uiSchema
          }}
          formContext={{
            fetchOptions,
            formData,
            refreshForm: props.refreshForm,
            ...formContext
          }}
          validator={customizeValidator({ AjvClass: Ajv2020 })}
          widgets={widgets}
          onChange={e => {
            onChange?.(e.formData as T);
          }}
          idPrefix=""
          idSeparator="."
        />
      </FormProvider>
    </FormLoadingContext.Provider>
  );
}

export function formValue(current, defaultValue: unknown): unknown {
  if (current === undefined || current === null || current === '') {
    return defaultValue;
  }
  if (Array.isArray(current) && current.length === 0) {
    return defaultValue;
  }
  if (typeof current === 'object' && Object.keys(current).length === 0) {
    return defaultValue;
  }
  return current;
}
