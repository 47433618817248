import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { Pill } from '~/components/v3';
import { FieldsetFragment } from '~/generated/graphql';
import { useModelFields } from '~/hooks';
import { cn } from '~/lib/utils';
import { isModelMapping, LocalIdentityMapping, Mapping, routes, sortByNameAndId } from '~/utils';

interface ModelsUsingProps {
  fields: (Mapping | LocalIdentityMapping)[];
  classNames?: {
    pill?: string;
    icon?: string;
    text?: string;
  };
}

export function ModelsUsing({ fields, classNames }: ModelsUsingProps) {
  const { modelFields } = useModelFields();

  const modelsUsed = useMemo(() => {
    const seen = new Set();
    const models: FieldsetFragment[] = [];
    fields.filter(isModelMapping).forEach(mapping => {
      if (!seen.has(mapping.model?.fieldset?.id)) {
        const field = modelFields?.find(f => f.id === mapping.model.id);
        if (field?.fieldset) {
          models.push(field.fieldset);
        }
        seen.add(mapping.model?.fieldset?.id);
      }
    });
    return uniqBy(models, m => m.id).sort(sortByNameAndId);
  }, [fields]);

  return !!modelsUsed.length ? (
    <div className=" contents ">
      <span className="text-sm text-gray-500">from</span>
      {modelsUsed.map(model => (
        <Pill
          classNames={{
            pill: cn('border-gray-50 bg-gray-50 py-1 px-2', classNames?.pill),
            icon: cn('h-4 w-4', classNames?.icon),
            text: cn('text-gray-500', classNames?.text)
          }}
          key={model.id}
          text={model.name}
          icon={model.connection?.type?.id}
          href={generatePath(routes.editModel, { fieldsetId: model.id })}
          truncateLength={64}
        />
      ))}
    </div>
  ) : (
    <></>
  );
}
